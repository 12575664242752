import {
    setCurrentLiveroom,
    setCurrentPlayingCatalog,
    setCurrentTrackId,
    setJoinedLiveroom,
    setPlaying,
    setPlaylist,
    setShowPlayer,
    setToggleScreen,
} from '@/store/actions/player'

let clientRoleOptions = {
    // Set latency level to low latency
    level: 2,
}

async function init() {
    window.Agora = window.Agora || { AgoraRTC: require('agora-rtc-sdk-ng') }
}

export async function startLiveStream({
    channel,
    accessToken,
    userId,
    dispatch,
    livestreamData,
    pubnub,
}) {
    init()

    window.dispatch = dispatch
    window.livestreamData = livestreamData
    window.roomId = channel
    window.Agora.client =
        window.Agora &&
        window.Agora.AgoraRTC.createClient({
            mode: 'live',
            codec: 'vp8',
        })
    await window.Agora.client.setClientRole('audience', clientRoleOptions)
    const joined = await window.Agora.client.join(
        process.env.NEXT_PUBLIC_AGORA_APP_ID,
        channel,
        accessToken,
        null
    )
    window.joinedUser = joined
    window.Agora &&
        window.Agora.client.on('user-published', handleUserPublished)
    window.Agora &&
        window.Agora.client.on('user-unpublished', handleUserUnpublished)
    window.Agora &&
        window.Agora.client.on('user-joined', (user) =>
            handleUserJoined(user, pubnub)
        )
    window.Agora &&
        window.Agora.client.on('user-left', (user) =>
            handleUserLeft(user, pubnub)
        )
    window.Agora &&
        window.Agora.client.on('live-streaming-error', (user) =>
            handleAgoraError(url, err)
        )
    window.Agora &&
        window.Agora.client.on(
            'connection-state-change',
            handleConnectionChange
        )
}

async function handleConnectionChange(curState, revState, reason) {
    if (curState === 'DISCONNECTING' || curState === 'DISCONNECTED') {
        // await deleteDoc(
        //     doc(
        //         db,
        //         `Room/${window.roomId}/guestParticipants`,
        //         `${window.joinedUser}`
        //     )
        // )
    }
}
async function handleAgoraError(url, err) {
    console.log({ url, err })
}

async function handleUserPublished(user, mediaType) {
    await window.Agora.client.subscribe(user, mediaType)

    const users =
        window.Agora.client && window.Agora.client.remoteUsers
            ? window.Agora.client.remoteUsers.map((o) => o.uid)
            : []

    if (mediaType === 'audio') {
        const audioTrack = user.audioTrack
        // Play the audio
        audioTrack.play()
    }
}

function handleUserUnpublished(user) {
    // console.log('agoraevent - handleUserUnpublished', user)
}

async function handleUserJoined(user, pubnub) {
    console.log('agoraevent - handleUserJoined', user)
    window.dispatch(setJoinedLiveroom(true))
    window.dispatch(setPlaying(true))
    window.dispatch(setShowPlayer(true))
    window.dispatch(setCurrentLiveroom(window.livestreamData))
    window.dispatch(setToggleScreen(true))
    window.dispatch(setPlaylist([]))
    window.dispatch(setCurrentTrackId(''))
    window.dispatch(setCurrentPlayingCatalog({}))
    if (window.joinedUser) {
        // const docRef = doc(
        //     db,
        //     `Room/${window.roomId}/guestParticipants`,
        //     `${window.joinedUser}`
        // )
        // await setDoc(docRef, {
        //     agoraSessionId: `${window.joinedUser}_noice`,
        //     agoraUid: `${window.joinedUser}`,
        //     name: 'noice_user',
        //     roomId: window.roomId,
        // })
        pubnub.subscribe({
            channels: [
                `livestream_room.${window.roomId}`,
                `livestream_speakers_room.${window.roomId}-pnpres`,
            ],
            withPresence: true,
        })
    }
}

async function handleUserLeft(user, pubnub) {
    console.log('user-left', user)
    leave(pubnub)
}

export async function leave(pubnub) {
    console.log('agoraevent - Leave')
    if (!window.Agora) return
    window.Agora.client && (await window.Agora.client.leave())

    window.dispatch(setPlaying(false))
    window.dispatch(setShowPlayer(false))
    window.dispatch(setJoinedLiveroom(false))
    window.dispatch(setCurrentLiveroom({}))

    pubnub.unsubscribe({
        channels: [
            `livestream_room.${window.roomId}`,
            `livestream_speakers_room.${window.roomId}-pnpres`,
        ],
    })

    window.Agora = null
    window.livestreamData = null
    window.roomId = null
    window.joinedUser = null
}
